@import "../../../styles/var.less";

.breadcrumb {
  margin-bottom: @padding-md;
}

.pageHeader {
  background-color: @component-background;
}

.page {
  padding: @padding-lg;
  // overflow: auto;
}


.content {
  // padding: 0 @padding-lg @padding-lg @padding-lg;  
}

.contentFullpage {
  padding: 0;
}
