.loginLayout {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(45deg, #8baaaa 0%, #ae8b9c 100%);
}

.logo {
    margin-bottom: 25px;
}

.form {
    width: 320px;
}


