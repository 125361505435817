.form {
  width: 100%;
}

.name {
  width: 320px;
}

.comment {
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
}

.italic {
  font-style: italic;
}
